ul {
  padding: 0;
}

.app {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app-page-content.hidden {
  display: none;
}

@media (max-width: 900px) {
  .app-page-content {
    margin-top: 50px;
  }
}

body,
html {
  overflow-x: hidden;
}

.rec.rec-arrow-right:hover:enabled {
  background-color: #11216c;
}

.rec.rec-arrow-left:hover:enabled {
  background-color: #11216c;
}

.rec.rec-item-wrapper {
  padding: 10px !important;
}
