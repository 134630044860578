.withoutTextDecoration {
  text-decoration: none;
  color: black;
}

.span {
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}
