/* Estilo del contenedor principal del select */
.custom-select-container {
  position: relative;
  width: '100%';
  display: flex;
  height: 58.88px;
}

/* Estilo del select personalizado cerrado */
.custom-select {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 8px;
  cursor: pointer;
  border-radius: 12;
  border: none;
  width: 160px;
}

/* Estilo del select personalizado abierto */
.custom-select.open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

/* Estilo de las opciones personalizadas */
.custom-options {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: 0;
  border-radius: 0 0 5px 5px;
  display: none;
}

/* Estilo de las opciones personalizadas cuando se muestran */
.custom-select.open + .custom-options {
  display: block;
  z-index: 9999;
}

/* Estilo de las opciones personalizadas individualmente */
.custom-option {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
}

.custom-option:hover {
  background-color: #f0f0f0;
}

.valid-phone-input {
  border-radius: 12;
  border: none;
  padding: 0 0.7rem;
  display: flex;
  flex: 1;
  margin-left: 0.8rem;
  min-width: 110px;
}

.valid-phone-input:focus {
  /* Estilos cuando el elemento con la clase ".valid-phone-input" está marcado */
  /* Otros estilos que desees aplicar cuando el elemento está marcado */
}

.invalid-phone-input {
  border: 1px solid #f44336;
  border-radius: 12;
}

.invalid-phone-input:focus {
  background-color: #ffb7b2;
}
