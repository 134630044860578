.image-bottom-banner-container{
  height: 475px;
  position: relative;
  bottom: 78px;
}

.image-bottom-banner{

}

.bottom-banner-text-container{
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  padding: 20px;
  span {
    color: white;
    font-family: Inter;
  }
}


.banner-title{
  font-weight: 700;
  font-size: 28px;
  line-height: 33.89px
}

.banner-description{
  font-weight: 400;
  font-size: 20px;
  line-height: 24.2px
}

@media (max-width: 1200px){
  .image-bottom-banner-container{
    bottom: 63px;
  }
}

@media (max-width: 900px){
  .image-bottom-banner-container{
    margin-top: 20px;
    height: 250px;
    bottom: 35px;
  }
  .image-bottom-banner{
    height: 300px;
  }
  .banner-description{
    line-height: 19.36px;
  }
}


