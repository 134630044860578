.section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.headng1 {
  font-family: 'Roboto', sans-serif;
  color: #0f1950;
}

.div-block-67 {
  width: 70%;
}

.text-privacidad {
  margin-top: 30px;
  font-size: 12px;
  text-align: left;
}

.subheading {
  font-family: 'Roboto', sans-serif;
  color: #0f1950;
}
