.top-review-card{
  display: flex;
  justify-content: space-between;
  .review-verified-container{
    display: flex;
    align-items: center;
    .review-verified{
      margin: 0px;
      font-weight: bold;
      font-size: 8px;
    }
  }
}

.review-card-container{
  height: 210px;
  width: 100%;
  max-width: 270px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  margin: 5px;
  &.unlimited{
    height: 100%;
    min-height: 210px;
  }
}

.name-review-card{
  margin-top: 10px
}

.review-description{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 12px 0;
  animation: close 0.1s linear 0.1s forwards;
}

.unlimited{
  animation: open 0.1s linear 0s forwards;
}

/* Text */
@keyframes open {
  from {
    line-clamp: 3;
    -webkit-line-clamp: 3;
  }
  to {
    line-clamp: initial;
    -webkit-line-clamp: initial;
  }
}

@keyframes close {
  from {
    line-clamp: initial;
    -webkit-line-clamp: initial;
  }
  to {
    line-clamp: 3;
    -webkit-line-clamp: 3;
  }
}
