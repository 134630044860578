.footer-top-container{
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
  width: 100%;
  .items{
    display: flex;
  }
}

.items-header{
  font-weight: 700;
  color: #F3F4F8;
  font-size: 14px;
}

.items-list{
}


li{
  list-style-type: none;
}

.fixup-footer-mid{
  display: flex;
  justify-content: center;
  .mid-content{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    .text{
      font-weight: bold;
      font-size: 25px;
      color: white;
      margin: auto;
    }
    .images{
      justify-content: center;
      display: flex;
      flex-direction: row;
      gap: 10px;
      >*{
        height: 40px;
      }
    }
  }
}

.terms-and-conditions{
  margin-top: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .top-text{
    margin: auto;
    font-size: 10px;
    font-weight: bold;
    color: white;
    >a{
      color: white;
    }
  }
  .bottom-text{
    margin-top: 12px;
    >p{
      text-align: center;
      font-size: 10px;
      font-weight: 400;
      color: white;
      margin: 0;
    }
  }
}
