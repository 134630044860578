.banner-text-container{
  display: flex;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
  gap: 25px;
  justify-content: center;
  padding: 10px;
}

.intro-banner-container{

}

.image-banner-container{
  height: 450px;
  position: relative;
  bottom: 100px;
}

@media(max-width: 900px){
  .intro-banner-container{
    margin-top: 70px
  }
  .banner-text-container{
    gap: 10px;
  }
  .image-banner-container{
    bottom: 150px;
    height: 200px;
  }
  .image-banner{
    height: 400px;
  }
}
