.long-text{
  display: flex;
  flex-direction: column;
  gap: 10px;
  >span{
    font-size: 12px;
    font-weight: 400;
  }
}

.banner-image{
  height: 100%;
}

.initial-banner-title{
  font-weight: bold;
  font-size: 40px
}

.initial-banner-subtitle{
  font-size: 20px;
  font-weight: 500;
}

@media(max-width: 900px){
  .long-text{
    gap: 5px
  }
  .initial-banner-title{
    font-size: 28px;
  }
  .initial-banner-subtitle{
    font-size: 16px;
  }
}
