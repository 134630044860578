.button-container{
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

@media(max-width: 900px){
  .button-container{
    justify-content: center;
    margin-top: 35px;
  }
}

.rec.rec-arrow {
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
}
/* round buttons on hover */
.rec.rec-arrow:hover {
  border-radius: 50%;
}
/* hide disabled buttons */
.rec.rec-arrow:disabled {
  visibility: hidden;
}
/* disable default outline on focused items */
/* add custom outline on focused items */
.rec-carousel-item:focus {
  outline: none;
}
