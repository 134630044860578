.text-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  > * {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.contact-section-container {
  background-color: white;
  padding: 20px;
}

.image-contact-container {
}

@media (max-width: 900px) {
  .contact-section-container {
    display: flex;
    flex-direction: column-reverse !important;
  }
  .image-contact-container {
    height: 300px;
    bottom: 99px;
  }
}

@media (min-width: 900px) {
  .image-contact-container {
    height: 300px;
    position: relative;
  }
}
