.social-network-container {
  .social-text {
    font-sizee: 14px;
    font-weight: 700;
    color: white;
    font-family: Inter;
  }
  .icons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 16px;
    > * {
      background-color: white;
      border-radius: 50px;
      width: 20px;
      height: 20px;
      display: flex;
      padding: 10px;
      justify-content: center;
      cursor: pointer;
    }
  }
}
